html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Segoe UI light", "Calibri", "Helvetica", "Arial", sans-serif;
}

.triFlags {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #f4f4f4;
  font-family: "Segoe UI", "Calibri", "Helvetica", "Arial", sans-serif;
}
.triFlags .flagsInner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.triFlags .flagsInner .flag {
  height: 10vw;
  border-radius: unset;
  cursor: pointer;
}
.triFlags .flagsInner .flag:hover {
  box-shadow: 0px 0px 5px 6px #c7c7c7;
}
.triFlags .flagsInner .flagCaption {
  max-width: 23vw;
}

.flagsBlurb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vh;
}
.flagsBlurb .flagsBlurbInner {
  width: 100%;
  height: 100%;
  padding-top: 8vh;
  text-align: center;
  color: #777;
}

.flagsBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  color: #777;
  text-align: center;
}
.flagsBottom .fgGreen {
  color: green;
}
.flagsBottom .fgBlue {
  color: #0066ff !important;
  cursor: pointer;
}

.flagDiv .flagCaption {
  text-align: center;
  color: #777;
}

:global(.ql-container) {
  color: #1b4070 !important;
}

:global(.ql-align-center) {
  text-align: center;
}

:global(h1, h2, h3, h4, h5) {
  text-shadow: grey;
}

:global(.umSelectDiv) {
  width: 100%;
}

:global(.liHoverBg) {
  background: #4774b354 !important;
}

:global(#Edit-RD-Presentation-dialog-title) {
  cursor: move;
  border: 1px solid #cccccccc;
}

:global(#Edit-Public-Datasheet-dialog-title) {
  cursor: move;
  border: 1px solid #cccccccc;
}

:global(button) {
  border-radius: unset !important;
}

:global(.MuiPaper-root.MuiDialog-paper) {
  overflow: hidden;
}