:global(#nav-client-logo) {
  height: 75%;
  margin-top: 7px;
  margin-left: 7px;
  float: left;
}
:global(#nav-client-logo) :global(#mxLogo) {
  max-height: 100%;
}

.logoLeft {
  display: flex;
  position: absolute;
  width: 30vw;
}
.logoLeft > div {
  position: relative !important;
}

.navRollUp {
  height: 0;
  animation: navRollUp 0.5s linear 3.5s forwards;
}

.noRollUp {
  height: calc(30px + 1vw);
}

.navHeader {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* 12px is width of scrollbar set in index.scss */
  xxxoverflow: hidden;
  /*  Need visible for Srx autocomplete  */
  background: #fff;
}
.navHeader .clientLogoALink {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 7px;
  padding-left: 20px;
}
.navHeader .clientLogoALink img {
  height: 1.8vw;
}
.navHeader .flagsLangDiv {
  float: right;
  width: fit-content;
  height: 100%;
  padding-right: 4.3vw;
  display: flex;
}
.navHeader .flagsLangDiv .flagsDiv {
  display: flex;
  align-items: center;
  padding-right: 2.25vw;
}
.navHeader .flagsLangDiv .flagsDiv a {
  margin-right: 0.75vw;
  border-radius: unset;
}
.navHeader .flagsLangDiv .flagsDiv a img {
  width: 3vw;
  height: 1.8vw;
  border-radius: unset;
}
.navHeader .flagsLangDiv .langDiv {
  display: flex;
  align-items: center;
  font-size: calc(5px + 0.75vw);
  color: #828282;
}
.navHeader .flagsLangDiv .langDiv .langDivText {
  touch-action: inherit;
}
.navHeader .flagsLangDiv .langDiv .langSelect {
  outline: none;
  font-size: calc(5px + 0.6vw);
  border: 1px solid transparent;
}
.navHeader nav {
  float: right;
  height: 100%;
  padding-top: 0.4vw;
  font-size: 100%;
}
.navHeader nav a {
  margin: 0;
  min-width: 3.75vw;
  height: 100%;
  padding: 0 0.5vw;
  text-align: center;
  vertical-align: middle;
  font-size: calc(5px + 0.8vw);
  text-decoration: none;
  color: #0a3c82;
  background: rgba(255, 255, 255, 0);
  will-change: background-color;
  cursor: pointer;
}
.navHeader nav a:hover, .navHeader nav a:active {
  color: #999;
}
.navHeader nav a svg {
  width: 1.25vw;
}
.navHeader .btnCms {
  color: green;
  padding: 0.25vw 1vw 0;
  border: 1px solid #ccc;
  xxxborder-radius: 5px;
  background: palegreen;
}

.oFlowHidden {
  overflow: hidden;
}

.active {
  background: rgba(0, 0, 0, 0.4) !important;
}

@keyframes navRollUp {
  0% {
    height: 0;
  }
  100% {
    height: calc(30px + 1vw);
  }
}
@media (max-width: 599px) {
  .flagsDiv,
.langDivText {
    display: none !important;
  }
}