.staticNoSlide {
  height: 11vw;
}

.footerDiv {
  flex-grow: 1;
  z-index: 2;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 2fr 2fr 1.25fr;
  grid-template-areas: "colLeft colMiddle colRight";
  width: 100%;
  height: 100%;
  padding: 1vh 2%;
  font-size: 0.9vw;
  color: #828282;
  background: white;
}
.footerDiv .footLeft {
  grid-area: colLeft;
  width: 100%;
  height: 100%;
  padding: 1%;
  background: white;
}
.footerDiv .footLeft .logos {
  display: flex;
  width: 100%;
  height: 100%;
}
.footerDiv .footLeft .logos .logo {
  width: 50%;
  height: 100%;
  display: grid;
  place-items: center;
}
.footerDiv .footLeft .logos .logo img {
  width: 9vw;
}
.footerDiv .footMiddle {
  background: white;
  border-left: 1px solid rgba(200, 200, 200, 0.3);
  padding: 1%;
  grid-area: colMiddle;
}
.footerDiv .footMiddle .title {
  text-align: center;
}
.footerDiv .footMiddle .sitemapDiv {
  xxxpadding: 0 100px;
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow {
  display: flex;
  margin-top: 0.5vw;
  text-align: center;
  line-height: calc(8px + 1vw);
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow .siteLink {
  flex: 1;
  color: #828282;
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow .siteLink a {
  text-decoration: none;
  color: #828282;
  font-size: calc(5px + 0.5vw);
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow .siteLink a:visited {
  text-decoration: none;
  font-size: calc(5px + 0.5vw);
}
.footerDiv .footMiddle .sitemapDiv .sitemapRow .siteLink a:hover {
  xxxtext-decoration: underline;
  color: #222;
  font-size: calc(5px + 0.5vw);
}
.footerDiv .footRight {
  background: white;
  border-left: 1px solid rgba(200, 200, 200, 0.3);
  padding: 1%;
  grid-area: colRight;
}
.footerDiv .footRight .title {
  text-align: right;
}
.footerDiv .footRight .title a,
.footerDiv .footRight .title a:visited,
.footerDiv .footRight .title a:hover {
  color: #0a3c82;
}
.footerDiv .footRight .contactDiv div {
  text-align: right;
  font-size: calc(5px + 0.5vw);
}
.footerDiv .title {
  padding-bottom: 10px;
  font-size: 1.25vw;
  cursor: pointer;
  color: #0a3c82;
}

.version {
  position: absolute;
  bottom: 0;
  right: 0;
  color: transparent;
  font-size: 1.2rem;
}

.UNUSEDfooterLanding {
  display: none !important;
  z-index: 1;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "colLeft colMiddle colRight";
  width: 100%;
  padding: 1vh 7%;
  color: #828282;
  background: white;
}
.UNUSEDfooterLanding .footLeft {
  xborder: 1px solid green;
  padding: 1%;
  grid-area: colLeft;
}
.UNUSEDfooterLanding .footLeft .logos {
  display: flex;
}
.UNUSEDfooterLanding .footLeft .logos .logo {
  width: 50%;
}
.UNUSEDfooterLanding .footLeft .logos .logo a img {
  width: 9vw;
}
.UNUSEDfooterLanding .footMiddle {
  xborder: 1px solid yellow;
  border-left: 1px solid rgba(200, 200, 200, 0.3);
  padding: 1%;
  grid-area: colMiddle;
}
.UNUSEDfooterLanding .footMiddle .title {
  text-align: center;
}
.UNUSEDfooterLanding .footMiddle .sitemapDiv {
  padding: 0 100px;
}
.UNUSEDfooterLanding .footMiddle .sitemapDiv .sitemapRow {
  display: flex;
  margin-top: 20px;
  text-align: center;
}
.UNUSEDfooterLanding .footMiddle .sitemapDiv .sitemapRow .siteLink {
  margin-left: 20px;
  color: #828282;
}
.UNUSEDfooterLanding .footMiddle .sitemapDiv .sitemapRow .siteLink a {
  text-decoration: none;
  color: #828282;
}
.UNUSEDfooterLanding .footMiddle .sitemapDiv .sitemapRow .siteLink a:visited {
  text-decoration: none;
}
.UNUSEDfooterLanding .footMiddle .sitemapDiv .sitemapRow .siteLink a:hover {
  text-decoration: underline;
}
.UNUSEDfooterLanding .footRight {
  border-left: 1px solid rgba(200, 200, 200, 0.3);
  padding: 1%;
  grid-area: colRight;
}
.UNUSEDfooterLanding .footRight .title {
  text-align: right;
}
.UNUSEDfooterLanding .footRight .contactDiv div {
  text-align: right;
}
.UNUSEDfooterLanding .title {
  padding-bottom: 10px;
  font-size: 1.7em;
  color: #0a3c82;
}

@media (max-width: 999px) {
  .footerDiv {
    display: block !important;
  }

  .footerDiv .footLeft .logos .logo {
    display: flex;
    justify-content: space-evenly;
  }
}