.galleriaDiv section {
  background: #f4f4f4;
  padding: 50px 0;
}
.galleriaDiv .container {
  max-width: 1044px;
  margin: 20px auto 0;
  padding: 0 20px;
}
.galleriaDiv .carousel {
  display: block;
  text-align: left;
  position: relative;
  margin-bottom: 22px;
}
.galleriaDiv .carousel > input {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
.galleriaDiv .carousel > input:nth-of-type(18):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -1700%;
}
.galleriaDiv .carousel > input:nth-of-type(17):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -1600%;
}
.galleriaDiv .carousel > input:nth-of-type(16):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -1500%;
}
.galleriaDiv .carousel > input:nth-of-type(15):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -1400%;
}
.galleriaDiv .carousel > input:nth-of-type(14):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -1300%;
}
.galleriaDiv .carousel > input:nth-of-type(13):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -1200%;
}
.galleriaDiv .carousel > input:nth-of-type(12):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -1100%;
}
.galleriaDiv .carousel > input:nth-of-type(11):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -1000%;
}
.galleriaDiv .carousel > input:nth-of-type(10):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -900%;
}
.galleriaDiv .carousel > input:nth-of-type(9):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -800%;
}
.galleriaDiv .carousel > input:nth-of-type(8):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -700%;
}
.galleriaDiv .carousel > input:nth-of-type(7):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -600%;
}
.galleriaDiv .carousel > input:nth-of-type(6):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -500%;
}
.galleriaDiv .carousel > input:nth-of-type(5):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -400%;
}
.galleriaDiv .carousel > input:nth-of-type(4):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -300%;
}
.galleriaDiv .carousel > input:nth-of-type(3):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -200%;
}
.galleriaDiv .carousel > input:nth-of-type(2):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: -100%;
}
.galleriaDiv .carousel > input:nth-of-type(1):checked ~ .carousel__slides .carousel__slide:first-of-type {
  margin-left: 0%;
}
.galleriaDiv .carousel > input:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel > input:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel > input:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel > input:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel > input:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel > input:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel > input:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(7) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel > input:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(8) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel > input:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(9) {
  box-shadow: 0px 0px 0px 5px rgba(151, 151, 151, 0.5);
}
.galleriaDiv .carousel__slides {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
}
.galleriaDiv .carousel__slide {
  position: relative;
  display: block;
  flex: 1 0 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 300ms ease-out;
  vertical-align: top;
  box-sizing: border-box;
  white-space: normal;
}
.galleriaDiv .carousel__slide figure {
  display: flex;
  margin: 0;
}
.galleriaDiv .carousel__slide div {
  position: relative;
  width: 100%;
}
.galleriaDiv .carousel__slide div:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 66.6666666667%;
}
.galleriaDiv .carousel__slide div > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.galleriaDiv .carousel__slide img {
  display: block;
  flex: 1 1 auto;
  object-fit: cover;
  border-radius: unset;
}
.galleriaDiv .carousel__slide figcaption {
  align-self: flex-end;
  padding: 20px 20px 0 20px;
  flex: 0 0 auto;
  width: 25%;
  min-width: 150px;
}
.galleriaDiv .carousel__slide .credit {
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.5);
  display: block;
}
.galleriaDiv .carousel__slide.scrollable {
  overflow-y: scroll;
}
.galleriaDiv .carousel__thumbnails {
  list-style: none;
  padding: 0;
  padding-top: 18px;
  margin: 0;
  display: flex;
  margin: 0 -10px;
}
.carousel__slides + .galleriaDiv .carousel__thumbnails {
  margin-top: 20px;
}
.galleriaDiv .carousel__thumbnails li {
  flex: 1 1 auto;
  max-width: calc((100% / 6) - 20px);
  margin: 0 10px;
  transition: all 300ms ease-in-out;
}
.galleriaDiv .carousel__thumbnails label {
  display: block;
  position: relative;
}
.galleriaDiv .carousel__thumbnails label:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.galleriaDiv .carousel__thumbnails label > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.galleriaDiv .carousel__thumbnails label:hover, .galleriaDiv .carousel__thumbnails label:focus {
  cursor: pointer;
}
.galleriaDiv .carousel__thumbnails label:hover img, .galleriaDiv .carousel__thumbnails label:focus img {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);
  transition: all 300ms ease-in-out;
  border-radius: unset;
}
.galleriaDiv .carousel__thumbnails img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: unset;
}