.overlay {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.contactModal {
  z-index: 9;
  position: fixed;
  top: 10vh;
  left: 30vw;
  width: 40vw;
  height: 70vh;
  padding: 2vh 3vw 3vh;
  color: #444;
  font-family: "Segoe UI light", Arial, sans-serif;
  background: #f4f4f4;
  perspective: 95vh;
}
.contactModal .card {
  touch-action: inherit;
}
.contactModal .card .frontFace {
  touch-action: inherit;
}
.contactModal .card .frontFace .closeX {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  color: dodgerblue;
  font-size: calc(8px + 1vw);
  border: 1px solid transparent;
}
.contactModal .card .frontFace .closeX:hover {
  color: firebrick;
  border: 1px solid firebrick;
  cursor: pointer;
}
.contactModal .card .frontFace .header {
  margin-bottom: 5vh;
}
.contactModal .card .frontFace .header .title {
  font-size: calc(12px + 1vw);
}
.contactModal .card .frontFace .header .text {
  font-size: calc(8px + 1vw);
}
.contactModal .card .frontFace .header .text span {
  color: dodgerblue;
}
.contactModal .card .frontFace .body .twofer,
.contactModal .card .frontFace .body .threefer {
  display: flex;
  justify-content: space-between;
  margin: 0.2vw auto 0;
}
.contactModal .card .frontFace .body .twofer .inputDiv,
.contactModal .card .frontFace .body .threefer .inputDiv {
  width: 49%;
}
.contactModal .card .frontFace .body .twofer .inputDiv .inputField,
.contactModal .card .frontFace .body .threefer .inputDiv .inputField {
  width: 99%;
  min-height: unset;
  padding: 0.2vw 0.5vw;
  font-size: calc(2px + 1vw);
  font-family: "Segoe UI Light", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  opacity: 0.9;
  border: none;
  border-radius: unset;
}
.contactModal .card .frontFace .body .twofer .inputDiv .bgPink,
.contactModal .card .frontFace .body .threefer .inputDiv .bgPink {
  background: pink;
}
.contactModal .card .frontFace .body .twofer .inputDiv .bgPink::placeholder,
.contactModal .card .frontFace .body .threefer .inputDiv .bgPink::placeholder {
  color: #777;
}
.contactModal .card .frontFace .body .twofer .inputDiv .bgLightPink,
.contactModal .card .frontFace .body .threefer .inputDiv .bgLightPink {
  background: #f3d9dda8;
}
.contactModal .card .frontFace .body .twofer .inputDiv .bgLightPink::placeholder,
.contactModal .card .frontFace .body .threefer .inputDiv .bgLightPink::placeholder {
  color: #777;
}
.contactModal .card .frontFace .body .textareaDiv {
  margin: 0.2vw auto 0;
}
.contactModal .card .frontFace .body .textareaDiv textarea {
  width: 100%;
  min-height: 15vh;
  padding: 0.2vw 0.5vw;
  font-size: calc(2px + 1vw);
  font-family: "Segoe UI Light", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  opacity: 0.9;
  border: none;
}
.contactModal .card .frontFace .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1vh;
}
.contactModal .card .frontFace .buttons .btnSend {
  cursor: pointer;
  margin-right: 1vw;
  padding: 1vw 3vw;
  color: white;
  font-size: calc(8px + 1vw);
  background: dodgerblue;
}
.contactModal .card .frontFace .buttons .btnSend:disabled {
  background: lightgrey;
}
.contactModal .card .backFace {
  font-size: calc(1px + 1vw);
}

.blurb {
  position: absolute;
  top: 105%;
  left: -20%;
  border: 1px solid transparent;
  xxxborder-radius: 5px;
}
.blurb .inner {
  padding: calc(6px + 1vw);
  font-size: calc(6px + 2vw);
  border: 1px solid transparent;
  xxxborder-radius: 5px;
}

.hideBlurb {
  display: none;
}

.showBlurb {
  animation: move 3s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.spinBlurb {
  animation: spin 3s linear forwards;
}

@keyframes spin {
  30% {
    transform: rotate(0deg);
    border: 1px solid transparent;
  }
  31% {
    border: 1px solid rgba(50, 50, 50, 0.2);
  }
  45% {
    border: 1px solid rgba(100, 100, 100, 0.4);
  }
  60% {
    border: 1px solid rgba(150, 150, 150, 0.6);
  }
  85% {
    border: 1px solid rgba(200, 200, 200, 0.8);
  }
  90% {
    transform: rotate(330deg);
    border: 1px solid rgba(200, 200, 200, 0.99);
  }
}
@keyframes move {
  30% {
    transform: translate(0%, 0%);
    border: 1px solid transparent;
  }
  99% {
    border: 1px solid transparent;
  }
  100% {
    transform: translate(80%, -280%);
    border: 1px solid #ccc;
  }
}
.rotate {
  transition: transform 1s;
  transform-style: preserve-3d;
}

.requiredField {
  xxxbackground: #c4ffc4ab;
}
.requiredField::placeholder {
  color: #777 !important;
}

.formControl {
  padding: 2px 5px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 5px;
}

.captcha {
  padding: 5px 10px;
  border: 1px solid #ccc;
}
.captcha .capQues {
  padding: 5px 0;
  color: #0261c7;
  font-size: calc(1px + 1vw);
  font-weight: 400;
  font-family: "Segoe UI";
}
.captcha .capAns {
  display: flex;
  width: 100%;
  xxxheight: 50px;
}
.captcha .capAns input {
  padding: 5px;
  font-size: calc(8px + 0.5vw);
  text-align: center;
}
.captcha .capAns input::placeholder {
  font-size: calc(6px + 0.5vw) !important;
}

.capErr {
  background: #ffc0cb61;
  /* pink with opacity .5 */
}

.accmodFieldRow {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.accmodFieldRow input[type=text],
.accmodFieldRow input[type=password] {
  width: 100%;
  min-width: 50%;
}
.accmodFieldRow input[type=text]::placeholder,
.accmodFieldRow input[type=password]::placeholder {
  font-size: calc(3px + 1vw);
  color: #aaa;
}
.accmodFieldRow .accmodBtnLeft {
  flex: 1;
  min-width: 50%;
}
.accmodFieldRow .accmodBtnLeft .capTitle {
  color: #0261c7;
  font-size: calc(1px + 1vw);
  font-weight: 400;
  font-family: "Segoe UI";
}
.accmodFieldRow .accmodBtnRight {
  text-align: right;
  width: 100%;
  padding-right: 1vw;
}
.accmodFieldRow .accmodBtnRight .ibutton {
  font-size: calc(6px + 1vw);
  padding: 2vh;
  border: 2px solid transparent;
}
.accmodFieldRow .ibutton {
  margin: 0;
  margin-left: 1vw;
  padding: 5px 1vw;
  white-space: nowrap;
  color: white;
  font-size: calc(1px + 1vw);
  background: #0073ea;
  border: 2px solid #0073ea;
  cursor: pointer;
}
.accmodFieldRow .ibutton:hover {
  color: #0073ea;
  background: white;
}
.accmodFieldRow .ibutton:disabled {
  background: lightgrey;
}

.thankYou {
  text-align: center;
  font-style: italic;
  font-size: calc(2px + 1vw);
}

@media (max-width: 999px) {
  .contactModal {
    left: 10vw;
    width: 80vw;
  }
}
@media (max-height: 1024px) {
  .contactModal {
    max-height: 550px;
  }
}