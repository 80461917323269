.authorizeDiv {
  width: 100%;
  height: 100vh;
  padding: 10vh 20vw;
  background: white;
  font-size: 2em;
}
.authorizeDiv .header h2,
.authorizeDiv .header h1 {
  text-align: center;
  color: steelblue;
}

.body {
  text-align: center;
  xxxpadding: 0 17vw;
  color: steelblue;
}

.regDate {
  display: inline-block;
  font-weight: bold;
}

.regName {
  display: inline-block;
  margin: 0 10px;
  font-weight: bold;
}

.regStatus {
  text-align: center;
}
.regStatus .regAnswer {
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  font-size: 1.3em;
}
.regStatus .yes {
  color: green;
  border-bottom: 1px solid green;
}
.regStatus .no {
  color: tomato;
  border-bottom: 1px solid tomato;
}

.footer {
  color: steelblue;
  text-align: center;
  margin-top: 25vh;
  font-size: 0.6em;
  font-style: italic;
}

.blackList {
  color: steelblue;
  margin-top: 5vh;
  text-align: center;
  font-weight: bold;
  font-style: italic;
}

.fgRed {
  color: tomato;
}